import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Umbau = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="10 Tipps für den Umbau" showCalc={false} />
            <Article>
                <p>
                    Egal ob du nur einen Raum, die ganze Wohnung oder dein Haus sanieren möchtest: jeder Umbau erfordert
                    gute Vorausplanung, um Fehler und unnötige Kosten zu vermeiden. In diesem Artikel geben wir dir zehn
                    Tipps, damit dein Umbau so reibungslos wie nur möglich klappt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>1. Durch gute Planung Zeit und Geld sparen</h2>
                <p>
                    Ganz gleich, ob du nur ein Zimmer oder ein ganzes Haus umbauen möchtest – du brauchst einen Plan, in
                    dem du deine Ziele genau formulierst und Prioritäten setzt. Dieser Plan umfasst nicht nur die
                    bevorstehenden Arbeiten, sondern auch ein genaues Budget für die jeweiligen Bereiche sowie einen
                    Zeitplan. Vorher solltest du dich intensiv mit dem Gebäude oder Raum und deinen eigenen Erwartungen
                    auseinandersetzen, um Kosten und Zeit richtig einschätzen zu können. Bevor du dir eine{" "}
                    <Link to="/artikel/online-kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Finanzierung
                    </Link>{" "}
                    überlegen kannst, sollte dein benötigtes Budget bereits feststehen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>2. Genügend Zeit einplanen</h2>
                <p>
                    Genügend Zeit für den Umbau einplanen bedeutet auch, dass du zum Beispiel an Trocknungszeiten beim
                    Tapezieren, Streichen oder dem Lackieren von Parkettböden und Ähnlichem denken musst. Auch die
                    Wartezeiten für Materialien sollten richtig eingeschätzt werden. Hier ist es am besten, den
                    Liefertermin immer etwas früher anzusetzen, als den Termin mit den Handwerker*innen. Sollte es zu
                    Verzögerungen oder anderen Problemen kommen, ist dieser Zeitpuffer notwendig, um eine Lösung zu
                    finden.
                </p>
                <hr />

                <h2>3. Budget festlegen</h2>
                <p>
                    Wenn du deine genauen Ziele und Wünsche formuliert hast, die Materialkosten und die Kosten für die
                    Arbeiten berechnet hast, kannst du einen genauen Kostenplan erstellen. So wie bei dem Zeitplan ist
                    auch hier ein Sicherheitspolster mitzurechnen, der dich bei unerwarteten Ausgaben vor Unangenehmen
                    bewahrt. Im schlimmsten Fall bleibt dir das Geld übrig, doch wenn es fehlt, stehen die ganze Arbeit
                    und all deine Pläne auf dem Spiel.
                </p>
                <p>
                    Informiere dich hier über Sanierungskredite und{" "}
                    <Link to="/artikel/darlehen-und-kredit/" target="_blank" rel="noreferrer noopener">
                        Bauspardarlehen
                    </Link>
                    . Hier gibt es viele gute Angebote, die dir dabei helfen, deinen Umbau zu finanzieren. Außerdem
                    werden verschiedene staatliche Förderungen für{" "}
                    <Link to="/artikel/bauen-oder-kaufen/" target="_blank" rel="noreferrer noopener">
                        Bauen
                    </Link>
                    , Renovieren und Sanieren angeboten, über die du dich auf jeden Fall informieren solltest.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>4. Profis engagieren</h2>
                <p>
                    Je kompetenter die Menschen sind, die dein Gebäude vor dem Kauf oder Umbau untersuchen und später
                    daran arbeiten, desto sicherer bist du vor bösen Überraschungen. Jeder gute Plan erfordert eine
                    genaue Kenntnis des Bestands und der erforderlichen Arbeiten. So kannst du die Auswahl von
                    Handwerker*innen optimieren und Kosten und Bauzeiten besser kalkulieren. Es ist immer besser, den
                    Fokus auf die Fachkenntnisse von Handwerker*innen zu legen und professionelle Beratung
                    heranzuziehen, als nur auf den Preis zu schauen. Denn im Endeffekt, können Pfusch und übersehene
                    Mängel dazu führen, dass doppelte Arbeit getan werden muss und du im Endeffekt viel mehr Geld
                    ausgibst.
                </p>
                <hr />

                <h2>5. Trotzdem bereit sein, selbst anzupacken</h2>
                <p>
                    Es gibt viele Umbauarbeiten, die man auch als Amateur*in mit ein wenig Hilfe von Freund*innen und
                    Bekannten hinbekommt. Man sollte sich selbst zwar nicht überschätzen, aber auch keinesfalls
                    unterschätzen. Bei Abbrucharbeiten, kleinen energetischen Sanierungen wie der Austausch von Ventilen
                    am Thermostat, beim Streichen von Wänden oder auch der Verlegung oder Sanierung von Böden, kann sehr
                    viel Geld gespart werden, wenn man es selbst macht. Vielleicht kennst du Menschen, die handwerklich
                    geschickt sind und dir dabei helfen können. Aber sei ehrlich mit dir selbst und überschätze diene
                    Fähigkeiten nicht. Denk auch an dein Arbeitstempo oder die Zeit, die dir zusätzlich zu anderen
                    Tätigkeiten überhaupt zur Verfügung steht. So vermeidest du unnötige Verzögerungen, Stress oder
                    sogar Fehler, die später wieder ausgebessert werden müssen.
                </p>
                <hr />

                <h2>6. Pannen verhindern</h2>
                <p>
                    Um Pannen zu verhindern ist es also wichtig, die notwendigen Arbeiten richtig einzuschätzen, bei der{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Finanzierung
                    </Link>{" "}
                    immer mehr zur Verfügung zu haben, als man glaubt, zu brauchen und sich beim Umbau Hilfe von Profis
                    zu suchen. Du traust dir nicht zu den Parkettboden selbst zu schleifen und zu lackieren? Dann lass
                    es lieber jemanden machen, dem du vertraust.
                </p>
                <hr />

                <h2>7. Nicht am falschen Ort sparen</h2>
                <p>
                    Viele Leute sparen beim Umbau am falschen Ort. Sie bezahlen günstige Handwerker*innen oder kaufen
                    nur das günstigste Material und ärgern sich danach über das schlechte Ergebnis. Das kannst du
                    verhindern, indem du wirklich ehrlich einschätzt welche Arbeiten von wem gemacht werden sollten und
                    dir bei der Wahl von Materialien immer eine professionelle Beratung einholst.
                </p>
                <hr />

                <h2>8. Trotz genauem Plan Geduld und Flexibilität behalten</h2>
                <p>
                    Auch wenn du wirklich viel Zeit und Energie in einen genauen Plan gesteckt hast, können immer noch
                    unvorhergesehene Dinge passieren, die dazu führen, dass du deinen Plan daran anpassen musst. Versuch
                    Flexibel zu bleiben und Geduld zu haben - hierfür hast du dir schließlich die Zeitpuffer und die
                    finanziellen Polster zurechtgelegt. Vertrau darauf, dass am Ende alles klappen wird.
                </p>
                <hr />

                <h2>9. Handwerkern vertrauen und dich mit ihnen anfreunden</h2>
                <p>
                    Vertrauen solltest du nicht nur in den Prozess, sondern unbedingt auch in die Menschen, die den
                    Umbau realisieren. Deshalb ist es wichtig, die Handwerker*innen sorgfältig auszuwählen. Du kannst
                    schließlich nicht während des gesamten Umbaus anwesend sein und alles kontrollieren. Wenn du ein
                    gutes Verhältnis zu den Handwerker*inne und Profis hast, wird die Kommunikation viel einfacher
                    fallen, Missverständnisse vermieden und eben dieses notwendige Vertrauen hergestellt. Mach ihnen
                    einen Kaffee, unterhalte dich offen und gib ihnen einen Schlüssel, damit sie auch arbeiten können,
                    wenn du nicht da sein kannst. Ein gutes Verhältnis ist auch sehr wichtig, wenn du während des Umbaus
                    in der Wohnung oder dem Haus wohnen musst, während die Arbeiten noch in Gange sind.
                </p>
                <hr />

                <h2>10. Nicht auf den Spaß und die Freude vergessen!</h2>
                <p>
                    Ein Umbau kann eine äußerst nervenaufreibende Angelegenheit werden. Es wird viel Zeit und Geld
                    investiert und meist liegt einem das Haus oder die Wohnung sehr am Herzen, weil man darin leben
                    möchte und ein tolles Ergebnis sehen will. Es hilft, wenn du den gesamten Prozess dokumentierst. Mit
                    Vorher-Nachher-Fotos kannst du die Fortschritte direkt sehen und dich ihrer erfreuen. Wenn du
                    gemeinsam mit Freund*innen oder deiner Familie Hand anlegst, kann die viele Arbeit auch als Anlass
                    gesehen werden, um gemeinsam etwas Großartiges zu schaffen und einander näher zu kommen. Vergiss bei
                    all dem Stress also niemals auf die Freude am Prozess und dem Ergebnis.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"umbau"}></BreadcrumbList>
            <ArticleStructuredData page={"umbau"} heading={"10 Tipps für den Umbau"} />
        </Layout>
    );
};

export default Umbau;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.artikel.umbau"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
